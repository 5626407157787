import {
  FORMAT_DATE,
  MONTHS,
} from '~/constants/general';

export const useFormatDate = (date: number | string, format?: string) => {
  const { $i18n } = useNuxtApp();
  const t = $i18n.t;

  const optionsDate: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  };
  const optionsTime: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  };

  switch (format) {
    case FORMAT_DATE.ISO_DATE:
      return new Date(date).toISOString().split('T')[0];
    case FORMAT_DATE.DOT_DATE:
      return new Date(date).toLocaleDateString('de-DE', optionsDate);
    case FORMAT_DATE.TIME_AND_DATE:
      const newDate = new Date(date);
      let hours = newDate.getHours() as number | string;
      let minutes = newDate.getMinutes() as number | string;
      const day = newDate.getDate();
      const month = newDate.getMonth();
      const year = newDate.getFullYear();
      const monthName = MONTHS[month];

      hours = (Number(hours) < 10)
        ? '0' + hours
        : hours;
      minutes = (Number(minutes) < 10)
        ? '0' + minutes
        : minutes;

      return `${hours}:${minutes} ${day} ${t(`general_genitive_${monthName.toLowerCase()}`)} ${year}`;
    default:
      const formattedDate = new Date(date).toLocaleDateString('de-DE', optionsDate);
      const formattedTime = new Date(date).toLocaleTimeString('de-DE', optionsTime);

      return `${formattedDate} ${formattedTime}`;
  }
};
